import * as React from 'react';
import { useContext } from 'react';
import ProfileList from '../profile-list/profile-list.component';
import Course from '../course/course.component'
import Profile from '../profile/profile.component'
import Divider from '@mui/material/Divider';
import { ProfilesContext } from '../../contexts/profiles.context'


import './profile-panel.css';


const ProfilePanel = () => {
  const { selectedProfile, selectedCourseId } = useContext(ProfilesContext);

  return (
    <div >
      <div className='profileList'>
        <ProfileList />
      </div>
      <div className='profilePreview'>
        <div className='profile-container'>
          {selectedCourseId ? <><Course /><Divider /></> : null}
          <Profile profileData={selectedProfile} />
        </div>
      </div>
    </div>
  );
}

export default ProfilePanel;