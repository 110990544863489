import * as React from 'react';
import { useState, useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextInput from '../text-input/text-input.component';
import AlertDialog from '../alert-dialog/alert-dialog.component'
import { recalculateAllCourses } from '../profile/profile.component';
import { MD5 } from 'object-hash';
import { ProfilesContext } from '../../contexts/profiles.context'
import { calculateLaborCost, calculateSchedule } from '../../utils/calc.utils'

import './course.css'

const Course = () => {
  const { selectedProfile, saveProfile, selectedCourseId, selectCourse} = useContext(ProfilesContext);

  const [course, setCourse] = useState({});
  const [creditPoints, setCreditPoints] = useState();
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const hex = '0123456789ABCDEF'

let generateUUId = function() {
    let buffer = new Uint8Array(16)

    crypto.getRandomValues(buffer)

    buffer[6] = 0x40 | (buffer[6] & 0xF)
    buffer[8] = 0x80 | (buffer[8] & 0xF)

    let segments = []

    for (let i = 0; i < 16; ++i) {
        segments.push(hex[(buffer[i] >> 4 & 0xF)])
        segments.push(hex[(buffer[i] >> 0 & 0xF)])

        if (i == 3 || i == 5 || i == 7 || i == 9) {
            segments.push('-')
        }
    }

    return segments.join('')
}


  useEffect(() => {
    console.log("sel corse " + selectedCourseId);
    if (selectedProfile.courses && selectedCourseId && selectedProfile.courses.find(course => course.courseId === selectedCourseId)) {
      const course = selectedProfile.courses.find(course => course.courseId === selectedCourseId);
      setCourse(course);
      setCreditPoints(course.creditPoints);
    } else {
      console.log("empty course selected");
      setCourse({});
      setCreditPoints(null);
    }
  }, [selectedCourseId])

  const handleSaveConfirm = (event) => {
    recalculateAllCourses(selectedProfile);
    setOpenAlertDialog(false);
    saveCourseAndProfile(selectedProfile);
  };

  const handleSaveCancel = (event) => {
    setOpenAlertDialog(false);
  };

  const handleCourseNameChange = (newCourseName) => {
    course.courseName = newCourseName;
  };

  const handleActualCostChange = (newCost) => {
    course.actualLaborCost = newCost;
  };

  const handleActualScheduleChange = (newSchedule) => {
    course.actualSchedule = newSchedule;
  };

  const handleCreditPointsChange = (newCreditPoints) => {
    console.log("handleChange");
    setCreditPoints(newCreditPoints);
};

  useEffect(() => {
    const recalculate = async () => {
      const newLaborCost = await calculateLaborCost(selectedProfile, creditPoints)
      const newSchedule = calculateSchedule(newLaborCost, creditPoints);
      setCourse({
        ...course,
        "laborCost": newLaborCost,
        "schedule": newSchedule
      });
    }
    recalculate();
  }, [creditPoints, selectedProfile])

  const handleCourseSaveClick = (event) => {
    if (selectedProfile && selectedProfile.userId) { // not default profile

      if (selectedProfile.courses && selectedProfile.courses.length > 1 && selectedProfile.md5 !== MD5(selectedProfile.groupK) + MD5(selectedProfile.groupQ)) {
        setOpenAlertDialog(true);
      } else {
        saveCourseAndProfile(selectedProfile);

      }
    }
   
  };

  const saveCourseAndProfile = (profile) => {
    const newCourse = { ...course, 
      "creditPoints" : creditPoints
    }
    if (profile.courses) {
      const courses = profile.courses.filter(curCourse => curCourse.courseId !== course.courseId);
      profile.courses = [...courses, newCourse];
    } else {
      profile.courses = [newCourse];
    }
    profile.courses.filter(curCourse => curCourse.courseId == null).forEach(curCourse => {
      curCourse.courseId = generateUUId();
      selectCourse(curCourse.courseId);
    });
  

    saveProfile(profile);
  };

  return (
    <div>
      <AlertDialog okTitle="Save" onOkClick={handleSaveConfirm} onCancelClick={handleSaveCancel} openDialog={openAlertDialog} title="Profile Changed" text="Profile criteria has been changed! If you continue all related courses costs will be recalculated. If that is not intended, please cancel this action and save modified profile as copy. Would you like save and recalculate?"  />
      <Typography align="right" marginRight={3} marginTop={3} component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}>
          <TextInput id="standard-basic" label="Course name" variant="standard" val={course.courseName} onTextChange={handleCourseNameChange} />
          <Button variant="outlined" size="save" onClick={handleCourseSaveClick}>Save
          </Button>
      </Typography>

      <Stack sx={{ margin: '20px', alignItems: 'baseline' }} direction="row" spacing={4}>
        <Typography sx={{ width: '10', flexShrink: 0, alignItems: 'flex-end' }}> Course size  </Typography>
        <TextInput sx={{ width: '110px' }} id={"standard-basic"} label="ECTS" variant="standard" val={creditPoints} onTextChange={handleCreditPointsChange} />
        <Typography sx={{ width: '100', flexShrink: 0 }}>Estimated Effort</Typography>
        <Typography sx={{ color: 'text.secondary' }}>{course.laborCost?course.laborCost + " man/weeks":""}</Typography>
        <Typography sx={{ width: '100', flexShrink: 0 }}>Estimated Schedule </Typography>
        <Typography sx={{ color: 'text.secondary' }}>{course.schedule?course.schedule + " weeks":""}</Typography>
        <Typography sx={{ width: '10', flexShrink: 0, alignItems: 'flex-end' }}> Actual Effort  </Typography>
        <TextInput sx={{ width: '110px' }} id={"standard-basic1"} label="man/weeks" variant="standard" val={course.actualLaborCost} onTextChange={handleActualCostChange} />
        <Typography sx={{ width: '10', flexShrink: 0, alignItems: 'flex-end' }}> Actual Schedule  </Typography>
        <TextInput sx={{ width: '110px' }} id={"standard-basic2"} label="weeks" variant="standard" val={course.actualSchedule} onTextChange={handleActualScheduleChange} />

      </Stack>

    </div>
  )
}

export default Course;

