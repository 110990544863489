import * as React from 'react';
import { useContext } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { AddCircle, Clear } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import AddCard from '@mui/icons-material/AddCard';
import { Link } from 'react-router-dom'
import { ProfilesContext } from '../../contexts/profiles.context'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import './profile-list.css'

const ProfileList = () => {
  const [open, setOpen] = React.useState([]);

  const { profilesList, selectDefaultProfile, selectProfile, selectCourse, selectNewCourse, saveProfile, selectedCourseId, delProfile } = useContext(ProfilesContext);

  const handleClick = (event, profileId) => {
    const newState = open.filter((openProfileId) => openProfileId !== profileId);
    if (newState.length === open.length) {
      newState.push(profileId);
    }
    setOpen(newState);
  };

  const handleNewProfileClick = (event) => {
    selectDefaultProfile();
    selectCourse(null);
  };

  const handleNewCourseClick = (event, profileId) => {
    selectProfile(profileId);
    selectNewCourse();
  };

  const handleCourseClick = (event, profileId, courseId) => {
    selectProfile(profileId)
    selectCourse(courseId);
  };

  const handleCourseDeleteClick = (event, profileId, courseId) => {
    const profile = profilesList.find(profile => profile.id === profileId);
    if (profile && profile.courses) {
      const filteredCourses = profile.courses.filter(course => course.courseId !== courseId);
      profile.courses = [...filteredCourses];
      saveProfile(profile);
    }
    if (selectedCourseId === courseId) {
      selectDefaultProfile();
      selectNewCourse();
    }
  };

  const handleProfileDeleteClick = (event, profileId) => {
    delProfile(profileId);
    selectDefaultProfile();
  };

  const renderArrow = ({ profileId, courses }) => {
    if (courses && courses.length > 0) {
      return open.includes(profileId) ? <ExpandLess /> : <ExpandMore />
    }
    return null;
  }

  return (

    <List
      sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper', paddingTop: '5px' }}
      component="nav"
      aria-labelledby="nested-list-subheader"

      subheader={
        <Typography align='center' variant="h6" gutterBottom component="div"> Calculation profiles</Typography>
      }

    >
      <Typography align="right" paddingRight={2} paddingBottom={1}>
        <Link to='/' onClick={(event) => handleNewProfileClick(event)}>
          <AddCard color='primary' />
        </Link>
      </Typography>

      {profilesList.map((profile) => (
        <React.Fragment key={profile.id}>
          <ListItemButton onClick={(event) => handleClick(event, profile.id)}>
            <ListItemText primary={profile.profileName} onClick={(event) => { console.log('profile select'); handleCourseClick(event, profile.id, null) }} />
            {renderArrow(profile)}
            <IconButton onClick={(event) => handleNewCourseClick(event, profile.id)}>
              <AddCircle color='primary' />
            </IconButton>
            <IconButton aria-label="delete" onClick={(event) => handleProfileDeleteClick(event, profile.id)} >
              <DeleteIcon color='primary' />
            </IconButton>
          </ListItemButton>
          <Collapse in={open.includes(profile.id)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {profile.courses && profile.courses.map((course) => (
                <ListItemButton key={course.courseId} sx={{ pl: 4 }} >
                  <Link to='/' onClick={(event) => handleCourseClick(event, profile.id, course.courseId)}>
                    <ListItemText primary={course.courseName + " [" + course.laborCost + " m/w]"} />
                  </Link>
                  <IconButton onClick={(event) => handleCourseDeleteClick(event, profile.id, course.courseId)}>
                    <DeleteIcon sx={{ marginLeft: 1 }} color='primary' fontSize='small'  />
                  </IconButton>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
}

export default ProfileList;