import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';

import './section-option.css'

const SectionOption = (props) => {
    const muiName = ToggleButton.muiName;
    const toggleButtonProps = Object.assign({}, props);
    delete toggleButtonProps.onParamValueChange;

    return (
        <>
            <Container key={'cont' + props.id}>
                <ToggleButton {...toggleButtonProps} className="profile-section-option-label" style={{ textTransform: 'none', textAlign: 'left', display: 'inline' }} key={'toggle' + props.id} value={props.id} aria-label={props.id}>{props.label}
                </ToggleButton>
                <TextField id={props.id} className="profile-section-option-weight" style={{ display: 'inline-block', textAlign: 'end' }} key={'weight' + props.id} defaultValue={props.weight} variant="standard" onChange={props.onParamValueChange} />
            </Container>
        </>
    );
}

export default SectionOption