import { useState, useEffect } from 'react';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SectionOption from '../section-option/section-option.component'
import './section.css';

const Section = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(() => []);

  useEffect(() => {
    handleOption(null, props.options.filter(option => option.selected).map(option => option.paramId));
  }, [])

  const handleOption = (event, newOptions) => {
    setSelectedOptions(newOptions);
    props.options.forEach((option) => {
      if (newOptions.includes(option.paramId)) {
        option.selected = true;
      } else {
        option.selected = false;
      }
    });
    props.onToggleChange();
  };

  const handleParamValueChange = (event) => {
    const paramId = event.target.id;
    const newParamValue = event.target.value
    props.options
      .filter(option => option.paramId == paramId)
      .forEach(option => option.paramValue = Number(newParamValue));
    props.onToggleChange();
  };

  return (
    <ToggleButtonGroup
      orientation="vertical"
      color="primary"
      value={selectedOptions}
      onChange={handleOption}
      aria-label="Options"
    >
      {props.options.map((option) => (
        <SectionOption key={option.paramId} id={option.paramId} label={option.paramName} weight={option.paramValue} value={option.paramId} onParamValueChange={handleParamValueChange} />
      ))}
    </ToggleButtonGroup>
  );
}

export default Section