import * as React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';

const TextInput = (props) => {
    const[val, setVal] = useState(props.val);
    const textFieldProps = Object.assign({}, props);
    delete textFieldProps.onTextChange;
    
    useEffect(() => {
        setVal(props.val);
    }, [props.val])

    const handleChange = (event) => {
        setVal(event.target.value);
        props.onTextChange(event.target.value);
    };

    return (
        <TextField {...textFieldProps} value={val} onChange={handleChange}/>
    );
}

export default TextInput;
