import { useContext } from 'react'
import AppBar from '../../components/app-bar/app-bar.component';
import ProfilePanel from '../../components/profile-panel/profile.panel.component';
import Authentication from '../authentication/authentication.route';
import { UserContext } from '../../contexts/user.context';

import './home.route.css';

function Home() {
    const { currentUser } = useContext(UserContext);
    return (
        <div className="Home">
            <div className='homePage'>
                <div className='search'>
                    <AppBar />
                </div>
                <div>
                    {currentUser ? (
                        <ProfilePanel />
                    ) : (
                        <Authentication />
                    )}
                </div>
            </div>
        </div>
    );
}
export default Home;