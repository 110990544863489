import Home from "./routes/home/home.route";
import { Routes, Route } from 'react-router-dom'
import HelpPage from "./routes/help/help";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Home />}>
      </Route>
      <Route path="/help" element={<HelpPage/>}/>
    
    </Routes>
  );
}
export default App;