import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import GroupCriteria from '../section-group/section-group.component';
import TextInput from '../text-input/text-input.component';
import AlertDialog from '../alert-dialog/alert-dialog.component'
import { MD5 } from 'object-hash';
import { ProfilesContext } from '../../contexts/profiles.context'
import { calculateLaborCost, calculateSchedule } from '../../utils/calc.utils'

import './profile.css';

export const recalculateAllCourses = (profile) => {
  if (profile.courses){
    profile.courses.forEach(course => {
      const newLaborCost = calculateLaborCost(profile, course.creditPoints)
      const newSchedule = calculateSchedule(newLaborCost, course.creditPoints);
      course.laborCost = newLaborCost;
      course.schedule = newSchedule;
    })
  }
};

const Profile = () => {
  const { saveProfile, selectedProfile } = useContext(ProfilesContext);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [profileData, setProfileData] = useState(selectedProfile);

  useEffect(() => {
    setProfileData(selectedProfile);
  }, [selectedProfile]);

  const handleSaveConfirm = (event) => {
  recalculateAllCourses(profileData);
    setOpenAlertDialog(false);
    saveProfile(profileData);
  };

  const handleSaveCancel = (event) => {
    setOpenAlertDialog(false);
  };

  const handleProfileSaveClick = (event) => {
    if (profileData.userId && profileData.courses && profileData.courses.length > 1 && profileData.md5 !== MD5(profileData.groupK) + MD5(profileData.groupQ)) {
      setOpenAlertDialog(true);
    } else {
      saveProfile(profileData);
    }
  };

  const handleProfileSaveCopyClick = (event) => {
    saveProfile({ ... profileData, "userId" : null, "courses" : null });
  };

  const handleProfileNameChange = (newProfileName) => {
    profileData.profileName = newProfileName;
  };

  return (
    <>
      <AlertDialog okTitle="Save" onOkClick={handleSaveConfirm} onCancelClick={handleSaveCancel} openDialog={openAlertDialog} title="Profile Channged" text="Profile criteria has been changed! If you continue all related courses costs will be recalculated. If that is not intended, please cancel this action and save modified profile as copy. Would you like save and recalculate?"  />
      <Typography align="right" marginRight={3} marginTop={3} component="form"
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}>
          <TextInput id={"standard-basic" + profileData.id} label="Profile name" variant="standard" val={profileData.profileName} onTextChange={handleProfileNameChange} />
          <Button variant="outlined" size="save" onClick={(event) => handleProfileSaveClick(event)}>Save</Button>
          <Button variant="outlined" size="saveCopy" onClick={(event) => handleProfileSaveCopyClick(event)}>Save as Copy</Button>
      </Typography>
      <Typography mt={3} align='center' variant="h5" gutterBottom component="div">{profileData.groupK.groupName}</Typography>

      {profileData.groupK.groupCriteria.map((criteria) => (
        <GroupCriteria key={criteria.criteriaId} criteria={criteria} showSum='true' />
      ))
      }

      <Typography mt={3} align='center' variant="h5" gutterBottom component="div">{profileData.groupQ.groupName}</Typography>
      {profileData.groupQ.groupCriteria.map((criteria) => (
        <GroupCriteria key={criteria.criteriaId} criteria={criteria} />
      ))
      }
    </>
  );
}

export default Profile;