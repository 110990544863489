import { createContext, useState, useEffect, useContext } from 'react';
import { getProfilesList, postProfile, patchProfile, getDefaultProfile, deleteProfile } from '../utils/rest.utils';
import { UserContext } from './user.context'
import {MD5} from 'object-hash';

export const ProfilesContext = createContext({
  selectedProfile: {},
  profilesList: [],
});

export const ProfilesProvider = ({ children }) => {
  const { currentUser } = useContext(UserContext)
  const [defaultProfile, setDefaultProfile] = useState({});
  const [profilesList, setProfilesList] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [selectedCourseId, setSelectedCourseId] = useState();

  const selectDefaultProfile = () => {
    setSelectedProfile(structuredClone(defaultProfile));
  }

  const selectNewCourse = () => {
    setSelectedCourseId('New');
  }

  const selectCourse = (courseId) => {
    setSelectedCourseId(courseId)
    console.log("selecting course " + courseId);
  }

  const selectProfile = (profileId) => {
    const profile = profilesList.find(profile => profile.id === profileId)
    setSelectedProfile(profile);
  };

  const reloadProfileList = async () => {
    console.log("Loading profiles")
    return await getProfilesList(currentUser ? currentUser.accessToken : null)
    .then(res => res.map(profile => { profile.md5 = MD5(profile.groupK)+MD5(profile.groupQ); return profile }))
    .then(profilesList => {
      console.log(profilesList)
      setProfilesList(profilesList);
      console.log("loading done")
      return profilesList;
    });
  };

  const saveProfile = (profile) => {
    const save = async () => {
      if (profile.userId) {
        await patchProfile(currentUser.accessToken, profile)
        await reloadProfileList()
        .then(profiles => {
          setSelectedProfile(profiles.find(prof => prof.id === profile.id));
        });
      } else {
        const { profileId } = await postProfile(currentUser.accessToken, profile)
        await reloadProfileList()
        .then((profiles) => setSelectedProfile(profiles.find(prof => prof.id === profileId)));

      }
    };
    save();
  }

  const delProfile = (profileId) => {
    const del = async () => {
      await deleteProfile(currentUser.accessToken, profileId);
      reloadProfileList();
    };
    del();
  }

  useEffect(() => {
    if (currentUser){
      reloadProfileList();
    }
  }, [currentUser]);

  useEffect(() => {
    const getProfile = async () => {
      const profile = await getDefaultProfile();
      setDefaultProfile(profile);
      setSelectedProfile(structuredClone(profile));
      console.log("default loaded")
    };

    getProfile();
  }, []);

  const value = {
    selectDefaultProfile,
    selectProfile,
    setSelectedProfile,
    selectedProfile,
    profilesList,
    reloadProfileList,
    selectNewCourse,
    selectedCourseId,
    selectCourse,
    saveProfile,
    delProfile
  };
  return (
    <ProfilesContext.Provider value={value}>
      {children}
    </ProfilesContext.Provider>
  );
};
