export const calculateLaborCost = (profile, creditPoints) => {
    return round(creditPoints * sumK(profile.groupK) * creditPoints ** (1.01 + 0.24 * productQ(profile.groupQ)), 2);
};

export const calculateSchedule = (laborCost, creditPoints) => {
    const scheduleRatio = getScheduleRatio(creditPoints);
    if (scheduleRatio) {
        return round(scheduleRatio.n * laborCost ** scheduleRatio.e2, 2);
    }
    return null;
};

export const round = (value, decimalPoints) => {
    const roundingFactor = 10 ** decimalPoints;
    return Math.round(value * roundingFactor) / roundingFactor;
};

const scheduleRatios = {
    "1": { "n": 2.5, "e2": 0.38 },
    "3": { "n": 2.5, "e2": 0.35 },
    "7": { "n": 2.5, "e2": 0.32 }
};

const getScheduleRatio = (creditPoints) => {
    for (let i = creditPoints; i > 0; i--) {
        if (scheduleRatios[i]) {
            return scheduleRatios[i];
        }
    }
    return null;
};

const sumK = (groupK) => {
    let total = 0;
    groupK.groupCriteria.forEach((criteria) => {
        criteria.criteriaParam.forEach((param) => {
            if (param.selected) {
                total += param.paramValue
            }
        })
    }
    )
    return total;
};

const productQ = (groupQ) => {
    let total = 1;
    groupQ.groupCriteria.forEach((criteria) => {
        criteria.criteriaParam.forEach((param) => {
            if (param.selected) {
                total *= param.paramValue
            }
        })
    }
    )
    return total;
};
