import axios from 'axios'

const API = axios.create({
  // baseURL: `http://localhost:8080/api/`
  baseURL: `https://labor-cost.tsi.lv/api/`
});

class Headers {
  headers = {}
  constructor() {
    this.headers = {};
  }
  addHeader(name, value){
    this.headers = {... this.headers, [name]: value};
    return this.headers;
  }

  addAccessTokenHeader(accessToken){
    return this.addHeader("accessToken", accessToken);
  }
}

export const getProfilesList = (userAccessToken) => {
  const headers = new Headers().addAccessTokenHeader(userAccessToken);
  return getData(`/profiles`, headers)
};

export const getDefaultProfile = () => {
  return getData(`/profiles/default`)
};

export const postProfile = (userAccessToken, profile) => {
  const headers = new Headers().addAccessTokenHeader(userAccessToken);
  return postData(`/profiles`, profile, headers); 
};

export const patchProfile = (userAccessToken, profile) => {
  const headers = new Headers().addAccessTokenHeader(userAccessToken);
  return patchData(`/profiles/${profile.id}`, profile, headers); 
};

export const deleteProfile = (userAccessToken, profileId) => {
  const headers = new Headers().addAccessTokenHeader(userAccessToken);
  return deleteData(`/profiles/${profileId}`, headers); 
};

export const getData = async (apiUrl, headers) => {
  return await API.get(apiUrl, {headers: headers})
    .then(res => { console.log(res); return res.data }, error => console.log(error));
};

export const postData = async (apiUrl, data, headers) => {
  return await API.post(apiUrl, data, {headers: headers})
    .then(res => { console.log(res); return res.data }, error => console.log(error));
};

export const patchData = async (apiUrl, data, headers) => {
  return await API.patch(apiUrl, data, {headers: headers})
    .then(res => { console.log(res); return res.data }, error => console.log(error));
};

export const deleteData = async (apiUrl, headers) => {
  return await API.delete(apiUrl, {headers: headers})
    .then(res => { console.log(res); return res.data }, error => console.log(error));
};