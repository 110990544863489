import * as React from 'react';
import { useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { signOutUser } from '../../utils/firebase.utils';
import { UserContext } from '../../contexts/user.context';

import './app-bar.css'

const invertTheme = createTheme({
  palette: {
    white: {
      main: '#ffffff'
    } 
   },
});

const SearchAppBar = () => {
  const { currentUser } = useContext(UserContext);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Labor Cost Calculator
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <ThemeProvider theme={invertTheme}>
            {currentUser ? (
              <>
              <Typography variant="body2">{currentUser.displayName}</Typography>
              <Button variant="text" color='white' onClick={signOutUser}>SIGN OUT</Button>
              <Link to='/help'>
              <Button variant="text" color='white'>HELP</Button>
              </Link>

              </>
            ) : (
              <Link to='/'>
                <Button variant="text" color="white">SIGN IN</Button>
              </Link>
            )}
          </ThemeProvider>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default SearchAppBar;
