import * as React from 'react';
import { useState, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormHelperText from '@mui/material/FormHelperText';
import Section from '../section/section.component';
import { round } from '../../utils/calc.utils'
import { ProfilesContext } from '../../contexts/profiles.context'
import Stack from '@mui/material/Stack';
import WarningIcon from '@mui/icons-material/Warning';
import { Tooltip } from '@mui/material';

import './section-group.css';


const GroupCriteria = ({ criteria, showSum }) => {
    const { selectedProfile, setSelectedProfile } = useContext(ProfilesContext);
    const [expanded, setExpanded] = useState(false);
    const [criteriaSum, setCriteriaSum] = useState(0);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const updateTotal = () => {

        const total = criteria.criteriaParam
            .filter((param) => param.selected)
            .map((param) => param.paramValue)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

        setCriteriaSum(round(total, 2));
        setSelectedProfile({ ...selectedProfile });
    }

    return (
        <>
            <Accordion expanded={expanded === 'panel' + criteria.criteriaId} onChange={handleChange('panel' + criteria.criteriaId)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={'panel' + criteria.criteriaId + 'bh-content'}
                    id={'panel' + criteria.criteriaId + 'bh-content'}>
                    <Typography sx={{ width: '60%', flexShrink: 0 }}>
                        {criteria.criteriaName}
                    </Typography>
                    {showSum ?
                        <Stack spacing={0}>
                            <Typography sx={{ color: 'text.secondary' }}>
                                {criteriaSum} 
                                { criteriaSum > criteria.criteriaMaxValue ?
                                <Tooltip title='Section total coefficient is greater than max allowed'>
                                <WarningIcon sx={{ marginLeft: 1 }} color='warning' fontSize='small' /></Tooltip>
                                : null}
                            </Typography>
                            <FormHelperText id="component-helper-text">
                                (max {criteria.criteriaMaxValue})
                            </FormHelperText>
                        </Stack>
                        : null}
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ '& button': { m: 1 } }}>
                        <Section options={criteria.criteriaParam} onToggleChange={() => updateTotal()} />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>

    );
}

export default GroupCriteria;