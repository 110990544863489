import { useState } from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import {
  signInAuthUserWithEmailAndPassword,
  signInWithGoogleRedirect,
} from '../../utils/firebase.utils';

import './sign-in-form.css';

const defaultFormFields = {
  email: '',
  password: '',
};

const SignInForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const signInWithGoogle = async () => {
    await signInWithGoogleRedirect();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await signInAuthUserWithEmailAndPassword(email, password);
      resetFormFields();
    } catch (error) {
      console.log('user sign in failed', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className='sign-in-container'>
      <h2>Already have an account?</h2>
      <span>Sign in with your email and password</span>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
        <TextField
          label='Email'
          type='email'
          required
          onChange={handleChange}
          name='email'
          value={email}
          variant="standard"
        />

        <TextField
          label='Password'
          type='password'
          required
          onChange={handleChange}
          name='password'
          value={password}
          variant="standard"
        />
          <Stack spacing={2} direction="row">
            <Button variant="outlined" type='submit'>Sign In</Button>
            <Button variant="contained" color="primary" type='button' onClick={signInWithGoogle}>
              Sign In With Google
            </Button>
          </Stack>
        </Stack>
      </form>
    </div>
  );
};

export default SignInForm;
