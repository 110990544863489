import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './App.css';
import { BrowserRouter } from 'react-router-dom'
import { UserProvider } from './contexts/user.context';
import { ProfilesProvider } from './contexts/profiles.context'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <ProfilesProvider>
          <App />
        </ProfilesProvider>
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

