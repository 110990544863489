import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import help from './help.png';
import Course1 from './Course1.png';
import Course2 from './Course2.png';
import Course3 from './Course2.png';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function HelpPage() {
  return (
    <Box  sx={{ width: '100%' }}>
        <Link to='/'> 
        <Button variant="outlined" size="save">Back</Button>
        </Link>
        <Grid container spacing={2}>
        <Grid item xs={8}>
          <Item><img width={'90%'} src= {help}/></Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Typography width={'70%'} align="left"  variant="1">
            First step for starting of course creation effort evaluation is new Profile adding   
            </Typography>
              <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
                1) Click on button "Add new Profile"
              </Typography>
                <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
                  2) Select future Quality of Curse creation
                </Typography>
                  <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
                    3) Select Course developer experience level
                  </Typography>
                    <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
                      4) Name the Profile
                    </Typography>
                      <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
                        Profile will be main souce of evaluetion
                      </Typography></Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <img width={'90%'} src= {Course1}/>
          </Item>
        </Grid>
        <Grid item xs={8}>
          <Item>
            <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
              For adding new Course click on button nex to Profile name
            </Typography>
          </Item>
        </Grid>
        <Grid item xs={8}>
          <Item>
            <img width={'90%'} src= {Course2}/>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
              1) Add Credit points in field "Course size"
            </Typography>
              <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
                2) Name the Course calculation and save the changes
              </Typography>
          </Item>
        </Grid>  
          <Grid item xs={8}>
          <Item>
            <img width={'90%'} src= {Course3}/>
          </Item>
        </Grid>
        <Grid item xs={4}>
          <Item>
            <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
              1) Course size can be changed for checking results from different credit points perspective 
            </Typography>
              <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
                2) After having actual results they can be saved in Actuals section 
              </Typography>
                <Typography width={'70%'} align="left" gutterBottomgutterBottom component="div">
                  3) Actuals can be used for precising results in future. Open Profile and avaluete the added data, then change koeficients for geting calculated results as much as close to actual result.
                   Next calculation will be more precise and koeficients will be more corresponding to current user development skils
                </Typography>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
